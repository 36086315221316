import React, { Component } from 'react';
import { Link } from "react-router-dom";
class BoxPost extends Component {
	constructor(props) {
        super(props);
        this.state = {
            infobox: [
                {
                    id: '1',
                    srcimg: 'images/about/01.jpg',
                    subtitle:'Founder & CEO',
                    title: 'Saleh Alabdali',
                    description: 'has a CPA from new York state of accountancy & CPA from Ontario with over 14 years of experience in the field of external audit, financial consulting, and taxation.',
                    classdivider: 'dividers dividers-about-post',
                    linkedin: 'https://www.linkedin.com/in/saleh-alabdali-cpa-ca-anz-35821514/'
                },
                {
                    id: '2',
                    srcimg: 'images/about/02.jpg',
                    subtitle:'Founder & CEO',
                    title: 'Ahmad Almasri',
                    description: 'has a CPA from British Columbia with over 10 years of experience in the field of external audit, financial consulting, and taxation.',
                    classdivider: 'dividers dividers-about-post',
                    linkedin: 'a'
                },
            ]
            
        }
    }
    render() {
        return (
            <div>
                {
                    this.state.infobox.map(data =>(
                        <div key={data.id} >
                        <article className="entry">
                            {/* <div className="feature-post">                                    
                                <Link to="#">
                                    <img src={data.srcimg} alt="img" />
                                </Link>                                    
                            </div> */}
                            <div className="content-post">
                                {/* <div className="position">{data.subtitle}</div>	 */}
                                <h3 className="title-post"><Link to="#">{data.title}</Link></h3>
                                <div className="entry-post">
                                    <p>{data.description}</p>
                                </div>
                                {/* <div className="wrap-button s2">
                                    <a className="flat-button" href={data.linkedin} target="_blank" rel="noreferrer">Linkedin Profile</a>
                                    
                                </div>                                   */}
                            </div>
                        </article>
                         <div className={data.classdivider}></div>
                        </div>
                    ))
                }
                
            </div>
        )
                                
    }
}

export default BoxPost;