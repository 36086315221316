const menus = [
    {
        id: 1,
        name: 'Home',
        linkmenu: '/',
    },
    {
        id: 2,
        name: 'About',
        linkmenu: '/about-v1',        
    }
    ,
    {
        id: 3,
        name: 'Services',
        linkmenu: '/services-v2',
    }
]

export default menus;