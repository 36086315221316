import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class EventBoxs2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvents: [
                {
                    id: 4,
                    classicon: 'icon-rounded magic',
                    title: 'GST/HST Tax Returns',
                    description: 'Navigate the complexities of goods and services tax (GST) and harmonized sales tax (HST) requirements seamlessly with expert preparation of your tax returns, minimizing errors and maximizing refunds',
                },
                {
                    id: 5,
                    classicon: 'icon-rounded artboard',
                    title: 'Payroll and T4 Statements',
                    description: 'Streamline your payroll processes and fulfill your reporting obligations effortlessly with precise preparation of payroll documents and T4 statements, ensuring compliance with employment standards and tax laws',
                },
                {
                    id: 6,
                    classicon: 'icon-rounded global',
                    title: 'Company Incorporation',
                    description: 'Establish your business entity with confidence and clarity, leveraging professional assistance to navigate the legal requirements and administrative procedures of business incorporation, setting a solid foundation for your venture\'s success',
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="row">
                {
                    this.state.dataEvents.map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="iconbox-item">
                                <div className="iconbox style1">
                                    <div className="box-header">
                                        <div className={data.classicon}>
                                            
                                        </div>
                                        {/* <!-- /.icon-rounded --> */}
                                        <div className="box-title">
                                            <Link to="#" title="">{data.title}</Link>
                                        </div>
                                        {/* <!-- /.box-title --> */}
                                        </div>
                                        {/* <!-- /.box-header --> */}
                                    <div className="box-content">{data.description}</div>
                                    {/* <!-- /.box-content --> */}
                                </div>
                                 {/* <!-- /.iconbox --> */}
						    </div>
                            {/* <!-- /.iconbox-item --> */}
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default EventBoxs2;