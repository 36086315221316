import React, { Component } from 'react';
class MainPost extends Component {
	constructor(props) {
        super(props);
        this.state = {
            tabpost: [
                {
                    id: '1',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '2',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '3',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '4',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '5',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '6',
                    classcategory: 'wrap-one-half services',
                },
            ],
            boxcontent: [
                {
                    id: '1',
                    title: 'Consulting Services',
                    description: 'Business owners and the management team sometimes need a birds eye view to their business in order to ensure that all risks are being accounted for and all opportunities are being considered. In addition to this, they need to make sure that all their processes and procedures are efficiently attaining their objectives yet flexible to adapt to the changing environment. In this regard, we help businesses mitigate their risks, exploit new opportunities through mergers and acquisitions and restructure their organization to achieve the best outcome.',
                    flatlist: [
                    ],
                },
                {
                    id: '2',
                    title: 'Bookkeeping & Accounting',
                    description: 'Bookkeeping and accounting services involve the systematic recording, organizing, and analysis of financial transactions to ensure accurate and up-to-date financial records for a business. These services encompass several key activities:',
                    flatlist: [
                        {
                            id: '1',
                            text: 'Recording Transactions: Record all financial transactions, including sales, purchases, expenses, and payments, into the appropriate accounting software or ledger.'
                        },
                        {
                            id: '2',
                            text: 'Classification and Categorization: Each transaction is categorized and classified based on its nature and purpose, such as assets, liabilities, revenue, and expenses. This ensures proper accounting treatment and adherence to accounting principles.'
                        },
                        {
                            id: '3',
                            text: 'Reconciliation: Regular reconciliation of financial records, such as bank statements, invoices, and receipts, is conducted to ensure that the recorded transactions match external documents and accounts are accurately balanced.'
                        },
                    ],
                },
                {
                    id: '3',
                    title: 'Financial Statement & Reporting',
                    description: 'financial statement and reporting service involves the preparation, analysis, and presentation of a company\'s financial information in a clear, accurate, and compliant manner. This service encompasses the creation of essential financial statements such as balance sheets, income statements, cash flow statements, and statements of changes in equity. Additionally, it may involve providing insights into the financial performance, trends, and potential areas for improvement based on the data presented in these statements. Financial statement and reporting services are crucial for businesses to maintain transparency, meet regulatory requirements, make informed decisions, and communicate effectively with stakeholders such as investors, creditors, and regulatory bodies.',
                    flatlist: [
                    ],
                },
                {
                    id: '4',
                    title: 'Individual & Corporate Tax Returns',
                    description: 'The preparation of individual and corporate tax returns involves the systematic gathering, organizing, and reporting of financial information to calculate and file tax obligations accurately and in compliance with relevant tax laws and regulations. Here\'s a detailed description of the service:',
                    flatlist: [
                        {
                            id: '1',
                            text: 'Information Gathering: collect all relevant financial documents and information from individuals or corporate clients and other supporting documents.'
                        },
                        {
                            id: '2',
                            text: 'Data Analysis: Gathered financial data into tax preparation software or spreadsheets, ensuring accuracy and completeness. Carefully review the information to identify potential deductions, credits, and other tax-saving opportunities.'
                        },
                        {
                            id: '3',
                            text: 'Tax Calculations: Using the gathered financial data and tax regulations to calculate the taxable income for individuals or corporations, taking into account various deductions, exemptions, and credits available under the tax code.'
                        },
                        {
                            id: '4',
                            text: 'Completing Tax Forms: Based on the calculated taxable income, tax preparers complete the appropriate tax forms for individuals or corporations. Ensure that all required schedules, attachments, and disclosures are included and accurately filled out.'
                        },
                        {
                            id: '5',
                            text: 'Review and Verification: Before finalizing the tax returns, we carefully review all calculations, entries, and supporting documents to identify any errors, inconsistencies, or potential red flags that may trigger audits or penalties. '
                        },
                        {
                            id: '6',
                            text: 'Filing Tax Returns: Once the tax returns are complete and verified, tax preparers electronically file them with the relevant tax authorities '
                        },
                        {
                            id: '7',
                            text: 'Record-Keeping and Documentation: We advise our clients on the importance of maintaining accurate records and documentation to support the information reported on their tax returns. We provide guidance on record-keeping best practices and retention periods for different types of documents.'
                        },
                        {
                            id: '8',
                            text: 'Tax Planning and Advice: In addition to preparing tax returns, we offer tax planning services to help individuals and corporations minimize their tax liabilities legally. By providing strategic advice on tax-efficient investment strategies, timing of income and deductions, and other tax-saving strategies tailored to the client\'s specific financial situation and goals.'
                        },
                    ],
                },
                {
                    id: '5',
                    title: 'GST/HST Tax Returns',
                    description: 'The preparation of Goods and Services Tax (GST) or Harmonized Sales Tax (HST) returns is a critical aspect of tax compliance. Here\'s a detailed description of our service:',
                    flatlist: [
                        {
                            id: '1',
                            text: 'Transaction Recording: Businesses maintain detailed records of all sales and purchases made during the reporting period, including invoices, receipts, and other supporting documentation. Transactions subject to GST/HST are recorded separately to facilitate accurate reporting.'
                        },
                        {
                            id: '2',
                            text: 'Input Tax Credit (ITC) Calculation: For businesses registered for GST/HST, input tax credits (ITCs) can be claimed on eligible business expenses to recover the tax paid on purchases. Tax preparers identify and calculate the total amount of ITCs available based on the eligible expenses incurred during the reporting period.'
                        },
                        {
                            id: '3',
                            text: 'Output Tax Calculation: Businesses calculate the total amount of GST/HST collected on sales made during the reporting period. This includes both taxable goods and services sold to customers, as well as any GST/HST collected on behalf of the government, such as the provincial portion of HST.'
                        },
                        {
                            id: '4',
                            text: 'Net Tax Calculation: The net tax payable or refundable is determined by subtracting the total ITCs claimed from the total GST/HST collected. If the result is positive, indicating that more tax was collected than paid on expenses, the business owes GST/HST to the government. If the result is negative, indicating that more tax was paid on expenses than collected on sales, the business is eligible for a refund.'
                        },
                        {
                            id: '5',
                            text: 'GST/HST Return Preparation: Based on the calculations of input tax credits, output tax, and net tax payable/refundable, tax preparers complete the appropriate GST/HST return form prescribed by the tax authorities. This form includes detailed information about the business, its sales and purchases, and the calculated amounts of GST/HST owed or refundable.'
                        },
                        {
                            id: '6',
                            text: 'Filing the Return: Once the GST/HST return is prepared and reviewed for accuracy, we electronically file the return with the tax authorities, typically through the Canada Revenue Agency\'s (CRA) online filing system or other approved methods. The return must be filed by the due date specified by the tax authorities.'
                        },
                        {
                            id: '7',
                            text: 'Payment or Refund Processing: If the GST/HST return indicates a net tax payable, the business must remit the amount owed to the tax authorities by the due date. Conversely, if the return indicates a refund, the tax authorities will process the refund and issue it to the business accordingly.'
                        },
                    ],
                },
                {
                    id: '6',
                    title: 'Payroll & T4 Annual Statements',
                    description: 'The preparation of payroll and T4 annual statements is a crucial aspect of managing employee compensation and fulfilling tax reporting requirements for businesses. Here\'s a detailed description of our service:',
                    flatlist: [
                        {
                            id: '1',
                            text: 'Employee Data Management: Gathering and maintain accurate employee information, including personal details, tax withholding preferences, salary or hourly rates, benefits, and deductions. This information is securely stored in payroll systems or databases.'
                        },
                        {
                            id: '2',
                            text: 'Calculating Gross Pay: Using the employee data, payroll administrators calculate each employee\'s gross pay based on their hours worked, salary, commissions, bonuses, or other forms of compensation as per the applicable pay period.'
                        },
                        {
                            id: '3',
                            text: 'Deductions and Withholdings: Payroll administrators deduct various taxes, contributions, and withholdings from employees\' gross pay, including income tax, social security contributions, Medicare deductions, pension contributions, health insurance premiums, and other voluntary deductions such as retirement plan contributions or charitable donations.'
                        },
                        {
                            id: '4',
                            text: 'Net Pay Calculation: After deducting taxes and withholdings, payroll administrators calculate each employee\'s net pay, which is the amount they will receive in their paycheck. This net pay calculation takes into account any additional deductions or reimbursements, such as for employee benefits or expenses.'
                        },
                        {
                            id: '5',
                            text: 'Payroll Tax Compliance: We ensure compliance with tax laws and regulations by accurately calculating and withholding payroll taxes from employee wages, including federal income tax, province income tax.'
                        },
                        {
                            id: '6',
                            text: 'Filing T4 Annual Statements: At the end of the tax year, typically by the end of February in Canada, we prepare and distribute T4 statements to employees and submit them to the Canada Revenue Agency (CRA). The T4 statement summarizes each employee\'s total earnings, deductions, and taxes withheld throughout the year.'
                        },
                        {
                            id: '7',
                            text: 'Record-Keeping: We maintain detailed records of payroll transactions, including employee earnings, deductions, tax withholdings, and other relevant information. These records are essential for audit purposes, compliance with tax regulations, and resolving any discrepancies.'
                        },
                    ],
                },
                {
                    id: '7',
                    title: 'Company Incorporation',
                    description: 'Company Incorporation',
                    flatlist: [
                        {
                            id: '1',
                            text: 'Organizational restructuring.'
                        },
                        {
                            id: '2',
                            text: 'Enterprise risk management (ERM).'
                        },
                        {
                            id: '3',
                            text: 'Human capital.'
                        },
                    ],
                },
            ],
            
            
        }
    }
    render() {
        return (
            <div className="col-md-9 content-tab">
                {this.state.tabpost.map(data => {
                    let activeTabIdInt = 1;
                    if (this.props.activeTabId){
                        activeTabIdInt = this.props.activeTabId;
                    }
                    if (data.id === activeTabIdInt.toString()) {
                        console.log("In Tab changed to: ", data.id);
                        return (
                            
                            // Render the content for the active tab
                            <div className="content-inner" key={data.id} >
                            <div className={data.classcategory}>
                                <div className="v1">
                                    <div className="box-content">
                                        {
                                            this.state.boxcontent.filter(data => data.id === activeTabIdInt.toString())
                                            .map(data =>{
                                                return (
                                                    <div key={data.id} >
                                                        <div className="title">{data.title}</div>
                                                        <p>{data.description}</p>
                                                        <div className="dividers dividers-bc-v1"></div> 
                                                    </div>
                                                )
                                            }
                                            )
                                        }
                                        <ul className="flat-list">
                                            {
                                                this.state.boxcontent[activeTabIdInt-1].flatlist.map(data=>  (
                                                    <li key={data.id} >{data.text} </li>
                                                ))
                                            }
                                        </ul>
                                    </div>									
                                </div>
                            </div>
						</div>

                        );
                    }
                    return null;  // Do not render content for inactive tabs
                })}
            </div>
        )
                                
    }
}

export default MainPost;