import React, { Component } from 'react';

class TextMainFooter extends Component {
    render() {
        return (
            <div className="widget widget-text">
                <p>Any articles or publications contained within this website are not intended to provide specific business or investment advice. No responsibility for any errors or omissions nor loss occasioned to any person or organisation acting or refraining from acting as a result of any material in this website can, however, be accepted by the author(s) or Ontario Advisers Global. You should take specific independent advice before making any business or investment decision. Ontario Advisers is the brand used by a network of independent accounting and consulting firms, each of which practices in its own right. The network is not itself a separate legal entity of any description in any jurisdiction.
                </p>	
            </div>
            /* <!-- /.widget-text --> */
        );
    }
}

export default TextMainFooter;