import React, { Component } from 'react';
class MainServices extends Component {
	constructor(props) {
        super(props);
        this.state = {
            titleServices: [
                {
                    id: 1,
					title: 'What We Can Offer You',
					description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br> incididunt ut labore et dolore magna aliqua.',
                }
			],
			imagebox: [
				{
					id: '1',
					imgsrc: 'images/imagebox/04.jpg',
					title: 'Mutual Funds',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				},
				{
					id: '2',
					imgsrc: 'images/imagebox/05.jpg',
					title: 'Investment Planning',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				},
				{
					id: '3',
					imgsrc: 'images/imagebox/06.jpg',
					title: 'Personal Insurance',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				},
				{
					id: '4',
					imgsrc: 'images/imagebox/07.jpg',
					title: 'Industrial Insurance',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				},
				{
					id: '5',
					imgsrc: 'images/imagebox/08.jpg',
					title: 'INVESTMENT IN BONDS',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				},
				{
					id: '9',
					imgsrc: 'images/imagebox/09.jpg',
					title: 'Retirement Planning',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				}
			]
        }
    }
    render() {
        return (
			// Inside MainPost's render method
			<div className="col-md-9 content-tab">
			{
				this.state.boxcontent.map(data => {
				if (data.id === this.props.selectedMenuItemId.toString()) {
					return (
					<div key={data.id}>
						<div className="title">{data.title}</div>
						<p>{data.description}</p>
					</div>
					);
				}
				return null;
				})
			}
			</div>






        );
    }
}

export default MainServices;