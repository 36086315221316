import React, { Component } from 'react';
import { Link } from "react-router-dom";
class SlideBar extends Component {
	constructor(props) {
        super(props);
        this.state = {
            menutab: [
                {
                    id: 1,
					title: 'Consulting services',
                    class: 'active'
                },
                {
                    id: 2,
                    title: 'Bookkeeping & Accounting',
                    
                },
                {
                    id: 3,
                    title: 'Financial Statement & Reporting',
                    
                },
                {
                    id: 4,
					title: 'Individual & Corporate Tax Returns',
                },
                {
                    id: 5,
					title: 'GST/HST Tax Returns',
                },
                {
                    id: 6,
					title: 'Payroll & T4 Annual Statements',
                },
				
            ],
            titletab: [
                {
                    id: 1,
                    title: 'Our Brochure',
                    description: 'View our 2016 financial prospectus brochure for an easy to read guide on all of the services offered.'
                }
            ]
        }
    }
    render() {
        return (
            <div className="col-md-3">
            <div className="sidebar left">
                <aside className="widget widget_nav_menu">
                    <div className="menu-services-container">
                    <ul className="menu menu-tab">
                    {this.state.menutab.map(data => (
                        <li className={data.class} key={data.id} onClick={() => this.props.onTabChange(data.id)}>
                            <Link to="#">{data.title}</Link>
                        </li>
                    ))}
                    </ul>
                    </div>
                </aside>
            </div>
        </div>
        )
                                
    }
}

export default SlideBar;