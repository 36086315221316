import React, { Component } from 'react';
import SlideBar  from "./SlideBar";
import MainPost  from "./MainPost";
class Management extends Component {
	constructor(props) {
        super(props);
        this.state = {
            itembox: [
                {
                    id: 1,
					title: 'Consulting Services',
					classicon: 'icon-benefit',
					boxcontent: 'Enim ad minim veniam, quis nostrud exercitation ullamco laboris. Quis nostrud exercitation'
				},
                {
                    id: 2,
					title: 'Consulting Services2',
					classicon: 'icon-benefit',
					boxcontent: '2 Enim ad minim veniam, quis nostrud exercitation ullamco laboris. Quis nostrud exercitation'
				},
            ]
        }
        this.handleTabChange = this.handleTabChange.bind(this); // Ensure binding
    }
    
    handleTabChange(id) {
        this.setState({ activeTabId: id });
        
    }

    render() {
        return (
			
			<section className="flat-row pd-services-widget">
                <div className="container">
                    <div className="row flat-tabs" data-effect="fadeIn">
                        <SlideBar onTabChange={this.handleTabChange} />
                        <MainPost activeTabId={this.state.activeTabId} />
                    </div>              
                </div>
            </section>
                    // flat-row-iconbox
        );
    }
}

export default Management;